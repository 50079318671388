//message component for chat
import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { useCodeContext } from '../../contexts/CodeContext';
import FeedbackContext from '../../contexts/FeedbackContext';
import FeedbackInput from '../common/FeedbackInput'; // Import the FeedbackInput component
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import { PulseLoader } from 'react-spinners'; // For the loading icon waiting for GPT to respond
import { languageMappings } from '../coding/LanguageMapping'; // Import the language mappings for syntax highlighting
import styles from './ChatMessage.module.css';

const ChatMessage = ({ data, showIcon = true, fullWidth = false, pdfMode = false, feedbackMode = false, testAttemptId }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [userHasExpanded, setUserHasExpanded] = useState(false);//Enables keeping expanded evn as message grows from streaming
    const messageRef = useRef(null); // Ref for checking the message height
    const isSystemMessage = data.type === 'system';
    const isFirstMessage = data.isFirstMessage;
    // Code messages
    const codeRef = useRef(null); // Separate ref for code highlighting
    const [prismLangauge, setPrismLanguage] = useState('javascript'); // Default to JavaScript if no language is specified
    const { currentIdeLanguage } = useCodeContext();
    // Feedback
    const { feedbackMap } = useContext(FeedbackContext);
    const [isHoveringMessage, setIsHoveringMessage] = useState(false); // Show the feedback icons if they are hovering the message
    const evalId = data.id;
    const messageKey = `${testAttemptId}_${data.id}_${evalId}`;
    const shouldRenderFeedbackInput = feedbackMap[messageKey]?.eval || (isHoveringMessage && !pdfMode && feedbackMode && isSystemMessage); // Show the feedback icons if they are hovering the rationale or they have clicked thumbs up or down 

    // Syntax highlighting for code submissions
    useEffect(() => {
        if (data.isCodeSubmission && codeRef.current && data.codeLanguage) {
            // Set the Prism language based on the code language, using the mapping
            const language = languageMappings[data.codeLanguage].prism || 'javascript';
            setPrismLanguage(language);
            Prism.highlightElement(codeRef.current);
        }
    }, [data, currentIdeLanguage]);

    //dynamically import the language(s) file needed
    useEffect(() => {
        const loadPrismLanguage = async (language) => {
            try {
                if (language === 'php') {
                    return;
                }
                await import(`prismjs/components/prism-${language}`);
                if (codeRef.current) {
                    Prism.highlightElement(codeRef.current);
                }
            } catch (error) {
                console.error(`Error loading language ${language}:`, error);
            }
        };
    
        if (data.isCodeSubmission && data.codeLanguage) {
            if (prismLangauge === 'cpp') {
                // For C++, load both 'c' and 'cpp' components
                loadPrismLanguage('c').then(() => loadPrismLanguage('cpp'));
            } else if (prismLangauge === 'coffeescript') {
                // For CoffeeScript, load both 'javascript' and 'coffeescript' components
                loadPrismLanguage('javascript').then(() => loadPrismLanguage('coffeescript'));
            } else if (prismLangauge === 'typescript') {
                // For CoffeeScript, load both 'javascript' and 'coffeescript' components
                loadPrismLanguage('javascript').then(() => loadPrismLanguage('typescript'));
            } else {
                // For other languages, load the respective component
                loadPrismLanguage(prismLangauge);
            }
        }
    }, [data, prismLangauge]);

    // Automatically determine if the message should be collapsed based on its height
    useEffect(() => {
        // Only run this logic if the user hasn't manually expanded the message
        if (pdfMode) {
            setIsCollapsed(false);
        } else if (!userHasExpanded && messageRef.current) {
            const shouldCollapse = messageRef.current.clientHeight > 260 && (data.isCodeSubmission || data.instructionType === 'instructions' || data.isInstructions);
            setIsCollapsed(shouldCollapse);
        }
    }, [data, userHasExpanded]);

    // Toggle collapse state and send back to chatbox so it can adjust its height awareness for the scroll functionality
    const toggleCollapse = () => {
        if (isCollapsed) {
            setUserHasExpanded(true);
        }
        setIsCollapsed(!isCollapsed);
    };

    // Style for the container which is the outer div around the message box and the icon. The first message needs auto top margin to push it down
    const messageContainerStyle = `${styles.messageContainer} ${
        isSystemMessage ? styles.messageContainerSystem : styles.messageContainerUser
    } ${isFirstMessage ? styles.firstMessage : ''}`;

    // Style for the message box itself. User and system boxes need to be differentiated. Full width is used in the candidate report for the instructions message
    const messageBoxStyle = `${styles.messageBox} ${
        isSystemMessage ? styles.messageBoxSystem : styles.messageBoxUser
    } ${data.isCodeSubmission ? styles.codeSubmission : ''} ${fullWidth ? styles.fullWidth : ''}`;

    // If show icon is true show the icon for the message
    const iconContainer = isSystemMessage && showIcon ? (
        <div className={styles.iconContainer}>
            <span>IA</span>
        </div>
    ) : null;

    const messageContentStyle = `${styles.messageText} ${
        isCollapsed ? styles.collapsed : ''
    }`;

    let messageContent;

    // If it is insctructions, treat the html to format the messages and wrap in a div so its one element so the collapse works
    if (data.isInstructions || data.instructionType === 'instructions') {
        const instructionStyle = `${messageContentStyle} ${styles.instructions}`; // Add the instructions class
    
        messageContent = (
            <div className={instructionStyle} ref={messageRef}>
                <ReactMarkdown>{data.content}</ReactMarkdown>
            </div>
        );
    } else if (data.gpt === 'yes' && data.content === '') {
        // If itsa a GPT message and content in empty (as its calling the API) show the loader
        messageContent = (
            <div className={styles.loaderContainer}>
                <PulseLoader size={5} color={"#000"} loading={true} />
            </div>
        );
    } else if (data.isCodeSubmission) {
        // If its code, format as so
        messageContent = (
            <pre className={messageContentStyle} ref={messageRef}>
                <code className={`language-${prismLangauge}`} ref={codeRef}>
                    {data.content}
                </code>
            </pre>
        );
    } else {
        // Render regular message
        messageContent = (
            <p className={messageContentStyle} ref={messageRef}>
                {(typeof data.content === 'string' ? data.content.trimEnd() : data.content)} {/* Ensure it's a string and trim any trailing spaces or line breaks */}
                {feedbackMode && isSystemMessage && (
                    <span className={styles.trailingSpace}></span>
                )}
            </p>
        );             
    }

    return (
        <div className={messageContainerStyle}>
            {iconContainer}
            <div 
                className={messageBoxStyle}
                onMouseEnter={() => {
                    setIsHoveringMessage(true);
                }}
                onMouseLeave={() => {
                    setIsHoveringMessage(false);
                }}
            >
                {messageContent}
                {messageRef.current && messageRef.current.clientHeight > 260 && (data.isCodeSubmission || data.instructionType === 'instructions' || data.isInstructions) && (
                    <div className={styles.buttonContainer}>
                        <button 
                            className={`${styles.toggleButton} ${data.isCodeSubmission ? styles.codeToggleButton : ''}`} 
                            onClick={toggleCollapse}>
                            {isCollapsed ? 'Mostrar mais' : 'Mostrar menos'}
                        </button>
                    </div>
                )}
                {shouldRenderFeedbackInput && (
                    <FeedbackInput 
                        feedbackDetails={{ source: 'InterviewChat', test_attempt_id: testAttemptId, prompt_id: evalId, location: data.id, content: data.content }} 
                        popout={true}
                        chatMessage={true}
                    />
                )}
            </div>
        </div>
    );
};
export default ChatMessage;
